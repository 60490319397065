import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Typography, Paper } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import { init } from "emailjs-com";
import * as emailjs from "emailjs-com";
init("user_iLZ3jXyTzXi5zQFlgf5DG");

function IntakeRequests() {
  const [intake, setIntake] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/getAll?containerId=intake&databaseId=employees"
      )
      .then((res) => {
        setIntake(res.data.filter((request) => !request.archived));
      });
  }, []);

  const columns = [
    {
      field: "date",
      headerName: "Submitted",
      renderCell: (params) => new Date(params.row.date).toLocaleDateString(),
      flex: 0.25,
    },
    { field: "user", headerName: "User", flex: 0.25 },
    { field: "details", headerName: "Details", flex: 1 },
    { field: "urgency", headerName: "Urgency", flex: 0.25 },
    { field: "department", headerName: "Department", flex: 0.3 },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      type: "singleSelect",
      valueOptions: ["New", "Pending", "Denied", "Completed"],
      editable: true,
    },
  ];

  const processRowUpdate = (newRow) => {
    console.log(newRow);
    const updatedRow = { ...newRow, isNew: false };
    setIntake(
      intake.map((request) => {
        if (request.id === newRow.id) {
          return updatedRow;
        }
        return request;
      })
    );

    console.log(updatedRow);
    //handle send data to api
    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/update?containerId=intake&databaseId=employees&id=${newRow.id}`,
        {
          status: newRow.status,
        }
      )
      .then((res) => {
        console.log(res);

        // send email notification to user that status has been updated

        emailjs.send(
          "service_gekurtf",
          "template_15p468l", //CONTACT TEMPLATE
          {
            to_name: newRow.user,
            email: newRow.email,
            status: newRow.status,
            details: newRow.details,
          },
          "user_iLZ3jXyTzXi5zQFlgf5DG"
        );
      });
    return updatedRow;
  };

  const archive = () => {
    selected.forEach((id) => {
      axios
        .post(
          `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/update?containerId=intake&databaseId=employees&id=${id}`,
          {
            archived: true,
          }
        )
        .then((res) => {
          setIntake(intake.filter((request) => request.id !== id));
        });
    });
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Intake Requests
        </Typography>
        <Button
          color="error"
          sx={{ fontSize: "12px" }}
          variant="outlined"
          onClick={archive}
        >
          archive selected
        </Button>
      </Box>

      <Paper sx={{ mt: 2 }}>
        <DataGridPro
          columns={columns}
          rows={intake}
          density="compact"
          autoPageSize
          autoHeight
          checkboxSelection
          onRowSelectionModelChange={(e) => setSelected(e)}
          // onCellEditStop={(params, event) => {
          // 	console.log(params.reason);
          // }}
          processRowUpdate={processRowUpdate}
          // onProcessRowUpdateError={(error) => console.log(error)}
          // editMode='row'
          // rowModesModel={rowModesModel}
          // onRowModesModelChange={handleRowModesModelChange}
        />
      </Paper>
    </>
  );
}

export default IntakeRequests;
