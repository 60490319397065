import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import { DirectoryContext } from "../../../../App";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../../config/auth";
import MSGraphService from "../../../../api/MSGraphService";
import config from "../../../../config";
import styled from "@emotion/styled";

const { BlobServiceClient } = require("@azure/storage-blob");
const blobSasUrl =
  "https://tbconnectstorage.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-01-30T08:03:13Z&st=2024-01-30T00:03:13Z&spr=https&sig=kTFgjI0cbH%2BcDGLdPTGMljV8hlvklkttnlps5wyHxNw%3D";
const blobServiceClient = new BlobServiceClient(blobSasUrl);

function UpdateEmployees() {
  const containerName = "siteimages";
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const { instance } = useMsal();
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState(null);
  const directoryContext = useContext(DirectoryContext);

  // get current list of employees from context
  const { employees, setEmployees } = directoryContext;

  const deleteOldEmployees = async () => {
    // set progress to deleting old employees (this will open modal)
    setProgress("Deleting outdated employees list");

    // loop through employees and delete each one
    let employeePromises = employees.map((employee) => {
      return new Promise((resolve) => {
        // delete employee
        axios
          .get(
            `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/delete?containerId=employees&databaseId=employees&id=${employee.id}`
          )
          .then((res) => {
            resolve({
              success: true,
            });
          })
          .catch(() => {
            resolve({
              success: false,
              id: employee.id,
            });
          });
      });
    });

    await Promise.all(employeePromises).then((res) => {
      console.log(res);

      if (res.filter((employee) => employee.success === false).length > 0) {
        setError("Error deleting old employees");
        return;
      }
    });

    //call getnewemployees function
    getNewEmployees();
  };

  const getNewEmployees = async () => {
    // set progress to getting employees from microsoft
    setProgress("Getting updated employees from Microsoft");

    // use microsoft auth to get employees
    const activeAccount = await instance.getActiveAccount();
    let graphAPIToken;
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: activeAccount,
      });
      graphAPIToken = response.accessToken;
    } catch (err) {
      setError("Error getting access token from Microsoft");
      return;
    }

    const msConfig = {
      token: graphAPIToken,
      groupId: config.REACT_APP_SACRED_GROUNDS_GROUP_ID,
    };

    try {
      // update array of employees
      const transblueRes = await MSGraphService.getEmployeesByGroupId(msConfig);

      let transblueEmployees = transblueRes.data.value.filter(
        (item) => item.displayName
      );

      console.log(transblueEmployees);
      // call get employees images function

      const condoshieldRes = await MSGraphService.getEmployeesByGroupId({
        token: graphAPIToken,
        groupId: "3a7fd129-b0c8-49b9-872f-423d0a62433d",
      });

      const condoshieldEmployees = condoshieldRes.data.value.filter(
        (item) => item.displayName
      );

      const arr = [...transblueEmployees, ...condoshieldEmployees].sort(
        (a, b) => a.displayName.localeCompare(b.displayName)
      );

      getManagers(arr, graphAPIToken);
    } catch (err) {
      setError("Error getting employees from Microsoft");
      return;
    }
  };

  const getManagers = async (arr, graphAPIToken) => {
    console.log(arr);
    setProgress("Getting manager information");
    const managerPromises = arr.map((employee) => {
      return new Promise((resolve) => {
        axios
          .get(
            `https://graph.microsoft.com/v1.0/users/${employee.id}?$expand=directReports&$select=department,directReports,faxNumber,birthday,employeeHireDate`,
            {
              headers: {
                Authorization: `Bearer ${graphAPIToken}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              resolve({
                ...employee,
                directReports:
                  res.data.directReports?.map((user) => user.id) || [],
                department: res.data?.department,
                faxNumber: res.data?.faxNumber,
                birthday: res.data.birthday,
                hireDate: res.data.employeeHireDate,
              });
            } else {
              resolve(employee);
            }
          })
          .catch(() => {
            resolve(employee);
          });
      });
    });

    let result = await Promise.all(managerPromises);
    console.log(result);

    getImages(result, graphAPIToken);
  };

  const getImages = async (arr, graphAPIToken) => {
    const processedEmployees = [];

    // set progress to getting employee profile photos
    setProgress("Getting employee profile photos from Microsoft");
    // loop through list of employees and make api call to microsoft graph to fetch profile photo
    const imagePromises = arr.map((employee) => {
      return new Promise((resolve) => {
        axios
          .get(
            `https://graph.microsoft.com/v1.0/users/${employee.id}/photo/$value`,
            {
              headers: {
                Authorization: `Bearer ${graphAPIToken}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            // console.log(res);
            if (res.status === 200) {
              resolve({
                ...employee,
                image: res.data,
              });
            } else {
              resolve(employee);
            }
          })
          .catch(() => {
            resolve(employee);
          });
      });
    });

    let result = await Promise.all(imagePromises);

    const uploadTasks = result.map(async (employee, index) => {
      try {
        if (employee.image) {
          // save profile photo binary data to azure blob storage
          const blockBlobClient = containerClient.getBlockBlobClient(
            `${employee.displayName}.png`
          );
          await blockBlobClient.uploadBrowserData(employee.image);

          // save url to employee in the list of employees
          result[
            index
          ].image = `https://tbconnectstorage.blob.core.windows.net/siteimages/${employee.displayName}.png`;
        }
      } catch (err) {
        console.log(err);
      }

      // save each employee to cosmos db
      try {
        const res = await axios.post(
          `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/save?databaseId=employees&containerId=employees`,
          employee
        );

        processedEmployees.push(employee);
      } catch (err) {
        setError("Error saving employees to the database");
      }
    });

    await Promise.all(uploadTasks);

    setEmployees(
      processedEmployees.sort((a, b) =>
        a.displayName.localeCompare(b.displayName)
      )
    );
    setProgress(null);
  };

  const StyledBox = styled("div")(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "90%",
    maxWidth: "500px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    boxShadow: 24,
    borderRadius: "8px",
    padding: "60px 30px",
    textAlign: "center",
  }));

  return (
    <>
      <Modal open={progress !== null} onClose={() => setProgress(null)}>
        <StyledBox>
          {error ? (
            <Typography variant="h5">Oops! Something went wrong.</Typography>
          ) : (
            <CircularProgress />
          )}
          <Typography variant="h5" mt={3}>
            {error ? error : progress}
          </Typography>
        </StyledBox>
      </Modal>
      <Button
        sx={{ textTransform: "capitalize" }}
        variant="outlined"
        color="success"
        onClick={deleteOldEmployees}
      >
        Update employees
      </Button>
    </>
  );
}

export default UpdateEmployees;
