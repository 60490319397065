import { loginRequest } from "../config/auth";
import axios from "axios";
import MSGraphService from "../api/MSGraphService";
import config from "../config";

const employees = [
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Aimee Wescott",
    department: null,
    id: "6c9814cd-3c75-4447-a6e3-a97a14fd72e8",
    jobTitle: null,
    imAddresses: ["Wescotta@transblue.org"],
    mail: "Wescotta@transblue.org",
    givenName: "Aimee",
    surname: "Wescott",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Allan Andres",
    department: "Sales",
    id: "cec774e9-6987-4702-b50a-1cfe952ae871",
    jobTitle: "Inside Sales",
    imAddresses: ["andresa@transblue.org"],
    mail: "AndresA@transblue.org",
    givenName: "Allan",
    surname: "Andres",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Allison Bolomey",
    department: "Operations",
    id: "73832d60-ef00-43a3-9060-bae1131d3fc7",
    jobTitle: "Director of Operations",
    imAddresses: ["bolomeya@transblue.org"],
    mail: "BolomeyA@transblue.org",
    givenName: "Allison",
    surname: "Bolomey",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Amira Alloghani",
    department: "Operations",
    id: "57dcd48d-9cbf-4042-b2d3-8cd46805d5e7",
    jobTitle: "Call Center Represenative",
    imAddresses: ["alloghania@transblue.org"],
    mail: "AlloghaniA@transblue.org",
    givenName: "Amira",
    surname: "Alloghani",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Andrew Garcia",
    department: "Accounting",
    id: "872d3512-35b4-4804-95bc-016558832fea",
    jobTitle: "Billing Specialist",
    imAddresses: ["garciaa@transblue.org"],
    mail: "GarciaA@transblue.org",
    givenName: "Andrew",
    surname: "Garcia",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Angelyno Casayuran",
    department: "Operations",
    id: "df74e50c-20dc-4953-a23b-9db23c6857be",
    jobTitle: "Snow Account Manager",
    imAddresses: ["casayurana@transblue.org"],
    mail: "CasayuranA@transblue.org",
    givenName: "Angelyno",
    surname: "Casayuran",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Angelyno Casayuran.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Bien Salazar",
    department: "Operations",
    id: "1f801434-4e05-4082-9f78-ecf26c1efca9",
    jobTitle: "Account Specialist",
    imAddresses: ["SalazarB@transblue.org"],
    mail: "SalazarB@transblue.org",
    givenName: "Bien",
    surname: "Salazar",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Brett Estok",
    department: "Sales",
    id: "a5c4fb0d-a688-4e66-9193-cb1e104f6aef",
    jobTitle: "Vice President of Business Development",
    imAddresses: ["estokb@transblue.org"],
    mail: "EstokB@transblue.org",
    givenName: "Brett",
    surname: "Estok",
    mobilePhone: "856-630-5154",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Brett Estok.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Carl Andrew Buenviaje",
    department: "Operations",
    id: "6cb1f20d-c7a9-4380-ae5c-e31cedd2f8d1",
    jobTitle: "Call Center Represenative",
    imAddresses: ["buenviajec@transblue.org"],
    mail: "BuenviajeC@transblue.org",
    givenName: "Carl Andrew",
    surname: "Buenviaje",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Carl Andrew Buenviaje.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Chevy Cotterell",
    department: "Operations",
    id: "b8c94464-b34e-45f1-823f-d6cf1b80cc66",
    jobTitle: "Director of Landscaping & Lot sweeping",
    imAddresses: ["cotterellc@transblue.org"],
    mail: "CotterellC@transblue.org",
    givenName: "Chevy",
    surname: "Cotterell",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Christopher Inog",
    department: "Operations",
    id: "3ed30e6f-7f36-443e-8f17-81c6fb654ff8",
    jobTitle: "Call Center Representative",
    imAddresses: ["inogc@transblue.org"],
    mail: "InogC@transblue.org",
    givenName: "Christopher",
    surname: "Inog",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Christopher Inog.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Corrie Ash",
    department: "Sales",
    id: "7dbdf7a0-d7df-4f48-a4de-7379df357002",
    jobTitle: "Executive Assistant",
    imAddresses: ["AshC@transblue.org"],
    mail: "AshC@transblue.org",
    givenName: "Corrie",
    surname: "Ash",
    mobilePhone: "425-239-7839",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Corrie Ash.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Dave Wescott",
    department: "Leadership",
    id: "e520229f-c636-4325-819f-613c48a32817",
    jobTitle: "CEO",
    imAddresses: ["wescottd@transblue.org"],
    mail: "wescottd@transblue.org",
    givenName: "Dave",
    surname: "Wescott",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "David Vetter",
    department: "Leadership",
    id: "5cb0b9ff-12d3-417c-9698-d25fce958bd1",
    jobTitle: "SR Vice President",
    imAddresses: ["david.vetter@evergreenbrands.com"],
    mail: "david.vetter@evergreenbrands.com",
    givenName: "David",
    surname: "Vetter",
    mobilePhone: "425-765-2253",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/David Vetter.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Dennis D'Esposito",
    department: "Operations",
    id: "5b779055-fbba-4719-af9c-7bade99fa093",
    jobTitle: "Project Manager",
    imAddresses: ["despositod@transblue.org"],
    mail: "DEspositoD@transblue.org",
    givenName: "Dennis",
    surname: "D'Esposito",
    mobilePhone: "(425) 891-0259",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Donald Anderson",
    department: "Marketing",
    id: "a1f77852-b0e6-4682-92d5-88fed42860a7",
    jobTitle: "Marketing Manager",
    imAddresses: ["andersond@transblue.org"],
    mail: "AndersonD@transblue.org",
    givenName: "Donald",
    surname: "Anderson",
    mobilePhone: "510-299-1786",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Donald Anderson.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Dylan Moses",
    department: "Leadership",
    id: "1e97d0f3-989e-4be5-a315-db0055648d3a",
    jobTitle: "Director of Snow Operations",
    imAddresses: ["MosesD@transblue.org"],
    mail: "MosesD@transblue.org",
    givenName: "Dylan",
    surname: "Moses",
    mobilePhone: "425-599-0233",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Dylan Moses.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Emmy Andino",
    department: "Operations",
    id: "4935e1b8-021d-408d-bf6b-fbc9df7915f0",
    jobTitle: "Snow Account Manager",
    imAddresses: ["andinoe@transblue.org"],
    mail: "AndinoE@transblue.org",
    givenName: "Emmy",
    surname: "Andino",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Emmy Andino.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Ernesto Ting",
    department: "Operations",
    id: "197caa13-c7b7-40d4-897a-7cca8f87c66c",
    jobTitle: "Call Center Represenative",
    imAddresses: ["tinge@transblue.org"],
    mail: "TingE@transblue.org",
    givenName: "Ernesto",
    surname: "Ting",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Ernesto Ting.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Eunice Aubrey Ordoña",
    department: "Operations",
    id: "b69a0ea1-a269-487c-a98f-16ad8beb1b85",
    jobTitle: "Executive Assistant to the VP of Operations",
    imAddresses: ["ordonae@transblue.org"],
    mail: "OrdonaE@transblue.org",
    givenName: "Eunice Aubrey",
    surname: "Ordoña",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Eunice Aubrey Ordoña.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Frank Fuentes",
    department: "Operations",
    id: "67ae6fd2-8285-496c-b1a1-48b378512689",
    jobTitle: "Snow Account Manager",
    imAddresses: ["fuentesf@transblue.org"],
    mail: "FuentesF@transblue.org",
    givenName: "Frank",
    surname: "Fuentes",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Gae Santos",
    department: "Operations",
    id: "63bba94e-4751-4278-b88a-a4858729c231",
    jobTitle: "Snow Account Manager",
    imAddresses: ["santosj@transblue.org"],
    mail: "SantosJ@transblue.org",
    givenName: "Gae",
    surname: "Santos",
    mobilePhone: "877-467-1131 ext. 224",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Gae Santos.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Glaida Fella Ecleo",
    department: "Operations",
    id: "21d82530-c907-45df-82de-57d7c0c8c434",
    jobTitle: "Call Center Representative",
    imAddresses: ["ecleog@transblue.org"],
    mail: "EcleoG@transblue.org",
    givenName: "Glaida Fella",
    surname: "Ecleo",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Glaida Fella Ecleo.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Ivan Sepulveda",
    department: "Call Blue - Center",
    id: "059f8914-a1b6-4d2c-a04d-8c976a23b7e6",
    jobTitle: "Call Blue - Center",
    imAddresses: ["sepulvedai@transblue.org"],
    mail: "SepulvedaI@transblue.org",
    givenName: "Ivan",
    surname: "Sepulveda",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Jacob Vetter",
    department: "Sales",
    id: "1f10a0c5-9e0e-42c5-8fe9-296d7204d4ec",
    jobTitle: "Inside Sales Executive",
    imAddresses: ["vetterj@transblue.org"],
    mail: "VetterJ@transblue.org",
    givenName: "Jacob",
    surname: "Vetter",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "James Maskell",
    department: "Operations",
    id: "ac66662a-7074-43bd-9183-e90a63723bad",
    jobTitle: "Project Manager",
    imAddresses: ["maskellj@transblue.org"],
    mail: "MaskellJ@transblue.org",
    givenName: "James",
    surname: "Maskell",
    mobilePhone: "2068041201",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Jay Pena",
    department: "Sales",
    id: "1dfc3728-967f-42ad-8573-e97e09f9ac2e",
    jobTitle: "Inside Sales",
    imAddresses: ["LaPenaJ@transblue.org"],
    mail: "LaPenaJ@transblue.org",
    givenName: "Jay",
    surname: "Pena",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Jay Pena.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Jim Wescott",
    department: "Leadership",
    id: "4325abbc-fc2a-448f-bd24-634734995bde",
    jobTitle: "Vice President of Technology",
    imAddresses: ["Jim.wescott@transblue.org"],
    mail: "Jim.wescott@transblue.org",
    givenName: "Jim",
    surname: "Wescott",
    mobilePhone: "425-577-1086",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Jim Wescott.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Joe Plando",
    department: "Operations",
    id: "a8a191c2-04f2-49ae-8130-a2a1686132a5",
    jobTitle: "Snow Account Manager",
    imAddresses: ["PlandoJ@transblue.org"],
    mail: "PlandoJ@transblue.org",
    givenName: "Joe",
    surname: "Plando",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Joe Plando.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Jonathan McQuade",
    department: "Leadership",
    id: "37390f88-8976-4add-81af-dcfaa822eb2e",
    jobTitle: "General Counsel",
    imAddresses: ["McQuadeJ@transblue.org"],
    mail: "McQuadeJ@transblue.org",
    givenName: "Jonathan",
    surname: "McQuade",
    mobilePhone: "206-913-7174",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Katherine Tumaliuan",
    department: "Operations",
    id: "4918bc23-b75b-4358-a982-58a2ee84e238",
    jobTitle: "Snow Account Manager",
    imAddresses: ["tumaliaunt@transblue.org"],
    mail: "TumaliaunT@transblue.org",
    givenName: "Katherine",
    surname: "Tumaliuan",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Katherine Tumaliuan.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Kaytee Hughes",
    department: "Operations",
    id: "64dbc854-b6c8-45d6-b132-5988283f3011",
    jobTitle: "Director of General Construction",
    imAddresses: ["hughesk@transblue.org"],
    mail: "HughesK@transblue.org",
    givenName: "Kaytee",
    surname: "Hughes",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Kaytee Hughes.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Kellie Ramsey",
    department: "Accounting",
    id: "a1f871ce-ac14-4fde-b91d-94eef13efaf2",
    jobTitle: "AP/AR Specialist",
    imAddresses: ["kelram@transblue.org"],
    mail: "kelram@transblue.org",
    givenName: "Kellie",
    surname: "Ramsey",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Kellie Ramsey.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Kristine Caryl Evangelista",
    department: "Operations",
    id: "46c70913-6d8e-4045-b0ed-13dede30cc9b",
    jobTitle: "Call Center Representative",
    imAddresses: ["evangelistak@transblue.org"],
    mail: "EvangelistaK@transblue.org",
    givenName: "Kristine Caryl",
    surname: "Evangelista",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Kristine Caryl Evangelista.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Larry Hyatt",
    department: "Leadership",
    id: "b845ac58-ec65-4ceb-8d2c-8987edd657c7",
    jobTitle: "Director of Operations",
    imAddresses: ["hyattl@transblue.org"],
    mail: "HyattL@transblue.org",
    givenName: "Larry",
    surname: "Hyatt",
    mobilePhone: "646-483-5048",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Lyra Guillermo",
    department: "Operations",
    id: "20993d63-9c8f-45c9-9800-2311bad697c4",
    jobTitle: "Snow Account Manager",
    imAddresses: ["guillermol@transblue.org"],
    mail: "GuillermoL@transblue.org",
    givenName: "Lyra",
    surname: "Guillermo",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Lyra Guillermo.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Madeliene Joy Cabral",
    department: "Operations",
    id: "6e54516d-b00d-4f75-bb47-8fceef46629f",
    jobTitle: "Snow Account Manager",
    imAddresses: ["cabralm@transblue.org"],
    mail: "CabralM@transblue.org",
    givenName: "Madeliene",
    surname: "Cabral",
    mobilePhone: "844-482-2583",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Madeliene Joy Cabral.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Maria Cristina Garcia",
    department: "Operations",
    id: "e3351d5b-1c38-43d1-a5f3-8d333368b9ac",
    jobTitle: "Call Center Representative",
    imAddresses: ["garciam@transblue.org"],
    mail: "GarciaM@transblue.org",
    givenName: "Maria Cristina",
    surname: "Garcia",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Maria Cristina Garcia.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Maricris Amoranto",
    department: "Operations",
    id: "6b8da1bf-da11-4a99-8e55-a61eac2f7730",
    jobTitle: "Call Center Representative",
    imAddresses: ["amorantom@transblue.org"],
    mail: "AmorantoM@transblue.org",
    givenName: "Maricris",
    surname: "Amoranto",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Maricris Amoranto.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Mary Fowler",
    department: "Accounting",
    id: "af7a8bcb-bad5-469c-b159-3f2abf0c7d12",
    jobTitle: "Billing Specialist",
    imAddresses: ["fowlerm@transblue.org"],
    mail: "FowlerM@transblue.org",
    givenName: "Mary",
    surname: "Fowler",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Mary Fowler.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Matthew Ping",
    department: "Operations",
    id: "483b54ba-d22e-483d-86e4-5f269648a0ac",
    jobTitle: "Project Manager",
    imAddresses: ["pingm@transblue.org"],
    mail: "PingM@transblue.org",
    givenName: "Matthew",
    surname: "Ping",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Melissa Bridges",
    department: "Accounting",
    id: "8299a7d2-2d54-43fd-9ded-30a40accfc5b",
    jobTitle: "Accounts Payable",
    imAddresses: ["bridgesm@transblue.org"],
    mail: "BridgesM@transblue.org",
    givenName: "Melissa",
    surname: "Bridges",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Michael Allen Ganzagan",
    department: "Operations",
    id: "ac19b8e6-bda3-4bc4-8507-8ce458d1a7e4",
    jobTitle: "Call Center Representative",
    imAddresses: ["ganzaganm@transblue.org"],
    mail: "GanzaganM@transblue.org",
    givenName: "Michael Allen",
    surname: "Ganzagan",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Michael Allen Ganzagan.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Michael De Jesus",
    department: "Operations",
    id: "b40d160e-756c-4200-9a88-13bda919a426",
    jobTitle: "Call Center Representative",
    imAddresses: ["dejesusm@transblue.org"],
    mail: "DeJesusM@transblue.org",
    givenName: "Michael",
    surname: "De Jesus",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Michael De Jesus.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Michael Lugasan",
    department: "Operations",
    id: "3848fd8c-b875-478e-b9e1-152c7569bac3",
    jobTitle: "Call Center Representative",
    imAddresses: ["lugasanm@transblue.org"],
    mail: "LugasanM@transblue.org",
    givenName: "Michael",
    surname: "Lugasan",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Michael Lugasan.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Patrick Sullivan",
    department: "Sales",
    id: "3e89b070-8bfb-4cf2-afaf-fc0fb0d34d6e",
    jobTitle: "Vice President of Business Development",
    imAddresses: ["sullivanp@transblue.org"],
    mail: "SullivanP@transblue.org",
    givenName: "Patrick",
    surname: "Sullivan",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Patrick Sullivan.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Paul Comer",
    department: "Sales",
    id: "3441ab2a-9860-4b46-bf3f-3bc308cada46",
    jobTitle: "Business Development",
    imAddresses: ["Comerp@transblue.org"],
    mail: "Comerp@transblue.org",
    givenName: "Paul",
    surname: "Comer",
    mobilePhone: "(206) 429-1841",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Paul Comer.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Ralph Helm",
    department: "Marketing",
    id: "8a2ed327-8325-4858-9114-cf42cce88453",
    jobTitle: "Video Lead",
    imAddresses: ["ralhel@transblue.org"],
    mail: "ralhel@transblue.org",
    givenName: "Ralph",
    surname: "Helm",
    mobilePhone: "937-470-2342",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Ralph Helm.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Reshell Abon",
    department: "Operations",
    id: "83eea290-0d22-4bfb-92d9-a57ca8eb5a32",
    jobTitle: "Snow Account Manager",
    imAddresses: ["abonr@transblue.org"],
    mail: "AbonR@transblue.org",
    givenName: "Reshell",
    surname: "Abon",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Richard Borja",
    department: "Operations",
    id: "dcbaf40c-d8aa-423e-b0fc-0b7c525f5f9d",
    jobTitle: "Call Center Representative",
    imAddresses: ["borjar@transblue.org"],
    mail: "BorjaR@transblue.org",
    givenName: "Richard",
    surname: "Borja",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Richard Borja.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Robert John Lumbes",
    department: "Operations",
    id: "d783bdff-5518-4a0c-ad5e-5d4a902bc284",
    jobTitle: "Snow Account Manager",
    imAddresses: ["lumbesr@transblue.org"],
    mail: "LumbesR@transblue.org",
    givenName: "Robert John",
    surname: "Lumbes",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Robert John Lumbes.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Robin Montemayor",
    department: "Operations",
    id: "dd7402f0-42da-4ebe-b37f-6a35c3248caa",
    jobTitle: "Call Center Representative",
    imAddresses: ["montemayorr@transblue.org"],
    mail: "MontemayorR@transblue.org",
    givenName: "Robin",
    surname: "Montemayor",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Ryan Evangelista",
    department: "Operations",
    id: "bd1144a0-601c-4c96-ab99-cd4baf056739",
    jobTitle: "Procurement Specialist",
    imAddresses: ["evangelistar@transblue.org"],
    mail: "EvangelistaR@transblue.org",
    givenName: "Ryan",
    surname: "Evangelista",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Ryan Evangelista.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Ryan Trinidad",
    department: "Operations",
    id: "cf8613fe-a735-4c3c-9227-5745c63febd1",
    jobTitle: "Call Center Representative",
    imAddresses: ["trinidadr@transblue.org"],
    mail: "TrinidadR@transblue.org",
    givenName: "Ryan",
    surname: "Trinidad",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Ryan Trinidad.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Sarah Carter",
    department: "IT",
    id: "744f3224-92dd-4d8c-8c8f-089299ab1f1a",
    jobTitle: "Lead Dev",
    imAddresses: ["CarterS@transblue.org"],
    mail: "CarterS@transblue.org",
    givenName: "Sarah",
    surname: "Carter",
    mobilePhone: "253-245-8544",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Sarah Carter.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Sheri Fleck",
    department: "Accounting",
    id: "af240862-fd27-4824-8c1e-f28c90a5d3e0",
    jobTitle: "Accountant",
    imAddresses: ["shefle@transblue.org"],
    mail: "SheFle@transblue.org",
    givenName: "Sheri",
    surname: "Fleck",
    mobilePhone: "206-272-0200",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Sheri Fleck.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Steve Higa",
    department: "Sales",
    id: "de860ccb-1568-4b30-a246-e1dbb9d265e2",
    jobTitle: "Vice President of Business Development",
    imAddresses: ["higas@transblue.org"],
    mail: "HigaS@transblue.org",
    givenName: "Steve",
    surname: "Higa",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Syron Agunias",
    department: "Operations",
    id: "6515da92-1581-4a4e-bc85-c337b01f3c95",
    jobTitle: "Snow Account Manager",
    imAddresses: ["aguniass@transblue.org"],
    mail: "AguniasS@transblue.org",
    givenName: "Syron",
    surname: "Agunias",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Syron Agunias.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Todd Chapman",
    department: "Leadership",
    id: "d9dba94d-0d3f-4924-ba6f-a1732e2fd6de",
    jobTitle: "VP of Franchise Development",
    imAddresses: ["ChapmanT@transblue.org"],
    mail: "ChapmanT@transblue.org",
    givenName: "Todd",
    surname: "Chapman",
    mobilePhone: "425-772-4970",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Todd Chapman.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Trevor Cease",
    department: "Marketing",
    id: "a35207c2-3436-46fe-8cf4-5592379af610",
    jobTitle: "Digital Marketing ",
    imAddresses: ["ceaset@transblue.org"],
    mail: "CeaseT@transblue.org",
    givenName: "Trevor",
    surname: "Cease",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Trevor Cease.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Vince Ortiz",
    department: "Leadership",
    id: "5fb12404-220f-4435-a049-92ea2f4487dd",
    jobTitle: "SR Vice President of Business Development",
    imAddresses: ["ortizv@transblue.org"],
    mail: "ortizv@transblue.org",
    givenName: "Vince",
    surname: "Ortiz",
    mobilePhone: "206-557-0147",
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Vince Ortiz.png",
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Virginia Nelson",
    department: "Business Development Representative",
    id: "89b1cb8f-7e98-4823-85d2-0f80f422eb41",
    jobTitle: "Business Development Representative",
    imAddresses: ["NelsonV@transblue.org"],
    mail: "NelsonV@transblue.org",
    givenName: "Virginia",
    surname: "Nelson",
    mobilePhone: null,
  },
  {
    "@odata.type": "#microsoft.graph.user",
    displayName: "Wendilyn Bamba",
    department: "Operations",
    id: "21f5354d-8545-4013-ae04-38e367ef9e7d",
    jobTitle: "Snow Account Manager",
    imAddresses: ["bambaw@transblue.org"],
    mail: "BambaW@transblue.org",
    givenName: "Wendilyn",
    surname: "Bamba",
    mobilePhone: null,
    image:
      "https://tbconnectstorage.blob.core.windows.net/siteimages/Wendilyn Bamba.png",
  },
];

const { BlobServiceClient } = require("@azure/storage-blob");
const blobSasUrl =
  "https://tbconnectstorage.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-01-30T08:03:13Z&st=2024-01-30T00:03:13Z&spr=https&sig=kTFgjI0cbH%2BcDGLdPTGMljV8hlvklkttnlps5wyHxNw%3D";
const blobServiceClient = new BlobServiceClient(blobSasUrl);

export const getAndSaveEmployees = async (instance) => {
  const containerName = "siteimages";
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const activeAccount = await instance.getActiveAccount();
  let graphAPIToken;
  try {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account: activeAccount,
    });
    graphAPIToken = response.accessToken;
  } catch (err) {
    console.log(err);
  }

  const msConfig = {
    token: graphAPIToken,
    groupId: config.REACT_APP_SACRED_GROUNDS_GROUP_ID,
  };

  const res = await MSGraphService.getEmployeesByGroupId(msConfig);
  let arr = res.data.value
    .filter((item) => item.displayName)
    .sort((a, b) => a.displayName.localeCompare(b.displayName));

  // array of promises to get each users profile photo
  const imagePromises = arr.map((employee) => {
    return new Promise((resolve) => {
      axios
        .get(
          `https://graph.microsoft.com/v1.0/users/${employee.id}/photo/$value`,
          {
            headers: {
              Authorization: `Bearer ${graphAPIToken}`,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve({
              ...employee,
              image: res.data,
            });
          } else {
            resolve(employee);
          }
        })
        .catch(() => {
          resolve(employee);
        });
    });
  });

  let result = await Promise.all(imagePromises);
  // setEmployees(result);

  console.log(result);

  result.forEach((employee, index) => {
    // save all employees images into azure storage account, then assign the url to the employee object

    try {
      if (employee.image) {
        // const arrBuff = toArrayBuffer(employee.image);
        const blockBlobClient = containerClient.getBlockBlobClient(
          `${employee.displayName}.png`
        );
        blockBlobClient.uploadBrowserData(employee.image);
        result[
          index
        ].image = `https://tbconnectstorage.blob.core.windows.net/siteimages/${employee.displayName}.png`;
      }
    } catch (err) {
      console.log(err);
    }

    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/save?databaseId=employees&containerId=employees`,
        employee
      )
      .then((res) => {
        console.log(res);
      });
  });

  console.log(result);

  // try {
  //   let employee = result[5];
  //   // const arrBuff = toArrayBuffer(employee.image);
  //   const blockBlobClient = containerClient.getBlockBlobClient(
  //     `${employee.displayName}.png`
  //   );
  //   blockBlobClient.uploadBrowserData(employee.image);
  // } catch (err) {
  //   console.log(err);
  // }
};
