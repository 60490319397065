import {
  Stack,
  TextField,
  Typography,
  Box,
  Button,
  IconButton,
  Autocomplete,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import Clear from "@mui/icons-material/Clear";
import axios from "axios";
import DepartmentSelect from "./components/departmentSelect";
import {
  generateEmailHtml,
  isValid,
  itemTemplate,
  initialValues,
} from "./functions/functions";
import Checkbox from "@mui/material/Checkbox";
import { useMsal } from "@azure/msal-react";
import { DirectoryContext } from "../../App";
import PreviousExpenseReports from "./previousExpenseReports";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StackComponent = ({ children }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="flex-end"
      sx={{ pr: 4, mb: 1 }}
      alignItems="center"
    >
      {children}
    </Stack>
  );
};

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = uuidv4();
    setRows((oldRows) => [
      ...oldRows,
      { ...itemTemplate, isNew: true, id: id },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "date" },
    }));
  };

  return (
    <GridToolbarContainer sx={{ flexGrow: 1 }}>
      <Button
        sx={{ textTransform: "capitalize", my: 1 }}
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClick}
        variant="outlined"
        size="small"
      >
        Add item
      </Button>
    </GridToolbarContainer>
  );
}

function ExpenseReport() {
  const [value, setValue] = useState(0);
  const { accounts } = useMsal();
  const account = accounts[0] || "";
  const [ccMyself, setCcMyself] = useState(false);
  const [errors, setErrors] = useState({
    employee: false,
    department: false,
    position: false,
    manager: false,
  });
  const [attachments, setAttachments] = useState([]);
  const [report, setReport] = useState(initialValues);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rows, setRows] = useState([
    {
      ...itemTemplate,
      id: uuidv4(),
    },
  ]);
  const directoryContext = useContext(DirectoryContext);
  const { employees } = directoryContext;
  const [reports, setReports] = useState([]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (account) {
      axios
        .post(
          "https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/get?containerId=expenseReports&databaseId=employees",
          {
            query: `SELECT * FROM c WHERE c.user = '${account.username}'`,
          }
        )
        .then((response) => {
          console.log(response);
          setReports(response.data.sort((a, b) => b._ts - a._ts));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    let savedReport = localStorage.getItem("saved report");

    if (savedReport) {
      savedReport = JSON.parse(savedReport);
      setReport(savedReport);
      setRows(savedReport?.rows);
    }
  }, []);

  const handleRowEditStop = (params, event) => {
    console.log(rowModesModel);
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
      setRowModesModel({
        ...rowModesModel,
        [params.row.id]: { mode: GridRowModes.View },
      });
    }
  };

  const handleEditClick = (id) => () => {
    console.log(rowModesModel);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    console.log("newRowModesModel", newRowModesModel);
    setRowModesModel(newRowModesModel);
  };

  const handleFileUpload = (e, params) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      // Use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      // add attachment to attachments array
      setAttachments([
        ...attachments,
        {
          filename: file.name,
          base64String: base64String,
          type: file.type,
        },
      ]);
    };
    reader.readAsDataURL(file);

    setRows((rows) =>
      rows.map((row) =>
        row.id === params.row.id ? { ...row, attachments: [file] } : row
      )
    );
  };

  const removeFile = (id) => {
    setRows((rows) =>
      rows.map((row) => (row.id === id ? { ...row, attachments: [] } : row))
    );
  };

  const updateDate = (e, params) => {
    console.log(e.target.value);
    console.log(
      rows.map((row) =>
        params.row.id === row.id ? { ...row, date: e.target.value } : row
      )
    );
    setRows(
      rows.map((row) =>
        params.row.id === row.id ? { ...row, date: e.target.value } : row
      )
    );
  };

  const saveForLater = () => {
    console.log(rows);
    localStorage.setItem(
      "saved report",
      JSON.stringify({ ...report, rows: rows })
    );
    alert("Report saved!");
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 0.5,
      editable: false,
      renderCell: (params) => (
        <input
          type="date"
          //   value={params.row.date}
          onChange={(e) => updateDate(e, params)}
        />
      ),
    },
    {
      field: "description",
      headerName: "Description/Who/Where",
      flex: 1,
      editable: true,
    },
    { field: "project", headerName: "Project", flex: 1, editable: true },
    {
      field: "total",
      headerName: "Total",
      flex: 0.5,
      editable: true,
      type: "number",
      renderCell: ({ value }) => (parseInt(value) ? value : 0),
    },
    {
      field: "attachments",
      headerName: "Attachments",
      flex: 1,
      renderCell: (params) =>
        params.row.attachments?.length === 0 ? (
          <input type="file" onChange={(e) => handleFileUpload(e, params)} />
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => removeFile(params.row.id)} size="small">
              <Clear color="error" fontSize="small" />
            </IconButton>
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {params.row.attachments[0].name}
            </Typography>
          </Box>
        ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const submit = async (e) => {
    if (
      rowModesModel[Object.keys(rowModesModel)[0]]?.mode === GridRowModes.Edit
    ) {
      alert(
        "You have unsaved changes! Click OK to save changes and then submit"
      );
    } else {
      const valid = isValid(report, setErrors);
      console.log(valid);
      console.log(account);

      let username = account.username;

      if (valid) {
        try {
          // Save expense report to database
          let obj = {
            rows: rows,
            manager: report.manager,
            user: username,
            date: report.date,
            ccMyself: report.ccMyself,
            total: report.total,
            id: uuidv4(),
          };
          await axios.post(
            `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/save?containerId=expenseReports&databaseId=employees`,
            obj
          );
          setReports([obj, ...reports]);
        } catch (err) {
          alert("Failed to save expense report. Please try again.", err);
        }

        // Send email to employee and manager about the new report
        // Send email to accounting to notify them of the new report
        try {
          let email = generateEmailHtml(rows, report, ccMyself, username);

          // send email to accounting to notify them of the new report
          await axios
            .get(
              "https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/getAuth"
            )
            .then(async (res) => {
              console.log("token response", res);
              // send email to estimator to notify them of the new request, with link to request
              await axios
                .post(
                  `https://my-tb-cors.herokuapp.com/https://graph.microsoft.com/v1.0/users/no-reply@evergreenbrands.com/sendMail`,
                  email,
                  {
                    headers: {
                      "Content-type": "application/json",
                      Authorization: `Bearer ${res.data.access_token}`,
                    },
                  }
                )
                .then((res) => {
                  console.log(res);
                  localStorage.setItem("name", report.employee);
                  localStorage.setItem("department", report.department);
                  localStorage.setItem("position", report.position);
                  localStorage.setItem("manager", report.manager);
                  alert("Expense report submitted successfully!");
                });
            });
          // reset report to initial values
          setReport(initialValues);
          localStorage.removeItem("saved report");
          // reset rows
          setRows([
            {
              ...itemTemplate,
              id: uuidv4(),
            },
          ]);
        } catch (err) {
          console.log(err);
          alert("Oops! Something went wrong. Please try again later.");
        }
      }
    }
  };

  const handleChange = (e) => {
    setReport({ ...report, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: false });
  };

  useEffect(() => {
    // function to update total when rows are edited
    const total = rows.reduce((acc, row) => acc + row.total, 0);
    setReport({ ...report, total });
  }, [rows]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="New Expense Report" {...a11yProps(0)} />
          <Tab label="Previous Reports" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          spacing={3}
          sx={{ maxWidth: "1300px" }}
        >
          <TextField
            variant="standard"
            label="Name"
            name="employee"
            onChange={handleChange}
            value={report.employee}
            fullWidth
            required
            error={errors.employee}
            helperText={errors.employee && "This field is required"}
          />
          <DepartmentSelect
            handleChange={handleChange}
            value={report.department}
            variant="standard"
            error={errors.department}
          />

          <TextField
            fullWidth
            variant="standard"
            label="Position"
            name="position"
            onChange={handleChange}
            value={report.position}
            required
            error={errors.position}
            helperText={errors.position && "This field is required"}
          />
          <TextField
            variant="standard"
            fullWidth
            label="Manager"
            name="manager"
            onChange={(e) => {
              setReport({ ...report, manager: e.target.value });
            }}
            value={report.manager}
            required
          />
        </Stack>

        <Box
          sx={{
            mt: 3,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
            maxWidth: "1300px",
            bgcolor: "#fcfcfc",
          }}
        >
          <DataGrid
            showCellVerticalBorder
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              footer: EditToolbar,
            }}
            slotProps={{
              footer: { setRows, setRowModesModel },
            }}
            sx={{ mb: 2 }}
          />

          <StackComponent>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Total:
            </Typography>
            <TextField
              name="total"
              variant="standard"
              value={
                parseInt(report.total) ? Number(report.total).toFixed(2) : 0
              }
              size="small"
              sx={{
                "& .MuiInputBase-root": {
                  "& input": {
                    textAlign: "right",
                  },
                },
              }}
              readOnly
            />
          </StackComponent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mr: 4,
              mt: 3,
              mb: 5,
            }}
            alignItems="center"
          >
            <FormGroup>
              <FormControlLabel
                onChange={() => setCcMyself((ccMyself) => !ccMyself)}
                control={<Checkbox checked={ccMyself} />}
                label="CC Myself"
                sx={{ mb: 5 }}
              />
            </FormGroup>
            <Stack direction="column" spacing={1}>
              <Button
                variant="contained"
                onClick={submit}
                size="small"
                sx={{ textTransform: "capitalize", width: "200px", mb: 5 }}
              >
                submit
              </Button>
              <Button
                variant="outlined"
                sx={{ textTransform: "capitalize" }}
                onClick={saveForLater}
                disableElevation
                size="small"
              >
                Save for later
              </Button>
            </Stack>
          </Box>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <PreviousExpenseReports reports={reports} setReports={setReports} />
      </CustomTabPanel>
    </>
  );
}

export default ExpenseReport;
