import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import getDaysOfWeekBetweenDates from "../functions/getDaysOfWeekBetweenDates";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";
import { useMsal } from "@azure/msal-react";

const initialValues = {
  dates: [],
  hours: 0,
  reason: "",
  paid: true,
  status: "New",
  approvedByManager: false,
  approvedByLeadership: false,
};

function TimeOffForm({ setTimeOffOpen }) {
  const { accounts } = useMsal();
  console.log(accounts);
  const user = accounts[0];
  const [request, setRequest] = useState(initialValues);

  useEffect(() => {
    axios
      .get(
        "https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getAuth"
      )
      .then((res) => {
        axios
          .get(
            `https://graph.microsoft.com/v1.0/users/${user?.username}/manager`,
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${res.data.access_token}`,
              },
            }
          )
          .then((res) => {
            setRequest({
              ...request,
              manager: res.data.mail || "jim.wescott@evergreenbrands.com",
            });
          });
      });
  }, []);

  const sendEmail = () => {
    axios
      .get(
        "https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getAuth"
      )
      .then((res) => {
        console.log(res);
        let email = {
          message: {
            subject: "Time Off Request",
            body: {
              contentType: "HTML",
              content: `Hello, <br /><br />${
                user.name
              } has submitted a time off request.<br /><br />
							Dates: ${new Date(request.dates[0]).toLocaleDateString()} - ${new Date(
                request.dates[1]
              ).toLocaleDateString()}.<br />
							Hours: ${request.hours}<br />
							Reason: ${request.reason} 
							<br /><br /> 
							You can review it here: <a href="https://employees.transblueconnect.com/requests" target="_blank" rel="noreferrer"> https://employees.transblueconnect.com/requests</a><br /><br /> Best wishes,<br />Transblue Team`,
            },
            toRecipients: [
              {
                emailAddress: {
                  address: request.manager,
                  //address: 'carters@transblue.org'
                },
              },
              {
                emailAddress: {
                  //address: request.manager
                  address: "carters@transblue.org",
                },
              },
            ],
          },
        };

        // send email to estimator to notify them of the new request, with link to request
        axios
          .post(
            `https://my-tb-cors.herokuapp.com/https://graph.microsoft.com/v1.0/users/no-reply@transblue.org/sendMail`,
            email,
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${res.data.access_token}`,
              },
            }
          )
          .then((res) => console.log(res));
      });
  };

  const handleDateChange = (newValue) => {
    // check if start and end dates are both declared
    if (newValue[0] && newValue[1]) {
      console.log(newValue[1].$d > newValue[0].$d);

      let startDate = new Date(newValue[0].$d);
      let endDate = new Date(newValue[1].$d);

      let entry = {
        ...request,
        dates: [startDate, endDate],
      };

      // calculate the number of hours in weekdays of time range
      let hours = getDaysOfWeekBetweenDates(startDate, endDate);

      entry = {
        ...entry,
        hours: hours,
      };

      console.log(entry);

      setRequest(entry);
    }
  };

  const submit = () => {
    request.user = user.name;
    request.date = new Date().getTime();

    try {
      axios
        .post(
          "https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/save?containerId=timeoffRequests&databaseId=employees",
          request
        )
        .then(() => {
          setRequest(initialValues);
          setTimeOffOpen(false);
          sendEmail();
        });
    } catch {
      alert("Oops! There was an error. Please try again");
    }
  };

  return (
    <>
      <Stack direction="column" spacing={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDateRangePicker
            localeText={{ start: "Start", end: "End" }}
            size="small"
            onChange={(newValue) => handleDateChange(newValue)}
            slotProps={{ textField: { size: "small" } }}
          />
        </LocalizationProvider>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">
            Would you like to use PTO?
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={request.paid}
            label="Would you like to use PTO?"
            onChange={(e) =>
              setRequest({
                ...request,
                paid: e.target.value,
              })
            }
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
        <TextField
          value={request.reason}
          label="Reason"
          multiline
          minRows={4}
          onChange={(e) =>
            setRequest({
              ...request,
              reason: e.target.value,
            })
          }
        />
        <Button fullWidth variant="contained" onClick={submit}>
          submit request
        </Button>
      </Stack>
    </>
  );
}

export default TimeOffForm;
