import { useMsal } from "@azure/msal-react";
import {
  Button,
  Paper,
  Typography,
  Modal,
  Stack,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGridPro } from "@mui/x-data-grid-pro";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { init } from "emailjs-com";
import * as emailjs from "emailjs-com";
init("user_iLZ3jXyTzXi5zQFlgf5DG");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "90%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "8px",
  maxWidth: "500px",
  p: 3,
};

function Requests() {
  const leadership = [
    // 'carters@transblue.org',
    "david.vetter@evergreenbrands.com",
    "jim.wescott@transblue.org",
    "jim.wescott@evergreenbrands.com",
  ];
  const { accounts } = useMsal();
  const user = accounts[0];
  const [requests, setRequests] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeRequest, setActiveRequest] = useState({});
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [selected, setSelected] = useState([]);
  const [filter, setFilter] = useState("all");
  const handleOpen = (params) => {
    console.log(params);
    setOpen(true);
    setActiveRequest(params.row);
  };

  useEffect(() => {
    axios
      .get(
        "https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/getAll?containerId=timeoffRequests&databaseId=employees"
      )
      .then((res) => {
        console.log(res.data);
        let userEmail = user.username.toLowerCase();

        // allow jim and dave vetter to see all requests
        if (leadership.includes(userEmail)) {
          setRequests(res.data);
          setFilteredRequests(res.data.filter((request) => !request.archived));
        } else {
          // filter requests for all other users
          setRequests(
            res.data.filter(
              (request) =>
                request.manager?.toLowerCase() === userEmail ||
                user.name === request.user
            )
          );

          setFilteredRequests(
            res.data.filter(
              (request) =>
                // request.status !== 'Approved by Leadership' &&
                !request.archived &&
                (request.manager?.toLowerCase() === userEmail ||
                  request.user === user.name)
            )
          );
        }
      });
  }, []);

  const columns = [
    { field: "user", headerName: "User", flex: 0.5 },
    {
      field: "dates",
      headerName: "Dates",
      renderCell: (params) => (
        <Typography variant="body2">
          {new Date(params.row.dates[0]).toLocaleDateString()} -{" "}
          {new Date(params.row.dates[1]).toLocaleDateString()}{" "}
        </Typography>
      ),
      flex: 0.5,
    },
    { field: "hours", headerName: "Hours", flex: 0.25 },
    {
      field: "paid",
      headerName: "Using PTO",
      flex: 0.25,
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.paid ? "Yes" : "No"}
        </Typography>
      ),
    },
    { field: "reason", headerName: "Reason", flex: 1 },
    {
      field: "date",
      headerName: "Submitted",
      renderCell: (params) => (
        <Typography variant="body2">
          {new Date(params.row?.date).toLocaleDateString()}
        </Typography>
      ),
      flex: 0.5,
    },
    { field: "status", headerName: "Status", flex: 0.5 },
  ];

  function addToCalendar() {
    let event = {
      allDay: true,
      type: "Vacation",
      employee: activeRequest.user,
      created: 1690923085660,
      vacationDates: activeRequest.dates,
      hours: activeRequest.hours,
      birthday: "",
      anniversary: "",
      travelDates: [],
      wfh: {
        recurring: true,
        date: "",
        daysOfWeek: [],
      },
    };

    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/save?containerId=calendar&databaseId=employees`,
        event
      )
      .then((res) => console.log(res));
  }

  const updateStatus = (action) => {
    console.log(activeRequest);
    let newStatus = "";
    if (activeRequest?.manager.toLowerCase() === user.username.toLowerCase()) {
      action === "approve"
        ? (newStatus = "Approved by Manager")
        : (newStatus = "Denied by Manager");
    }
    if (leadership.includes(user.username.toLowerCase())) {
      if (action === "approve") {
        addToCalendar();
        newStatus = "Approved by Leadership";
      } else {
        newStatus = "Denied by Leadership";
      }

      // send email to notify user that their request has been approved
    }

    // if status is approved by manager, need to email jim and vetter that manager has changed status
    if (activeRequest?.status === "Approved by Manager") {
      emailjs.send(
        "service_gekurtf",
        "template_g0ljw4f", //CONTACT TEMPLATE
        {
          user: activeRequest.user,
          dates: `${new Date(
            activeRequest?.dates[0]
          ).toLocaleDateString()} - ${new Date(
            activeRequest?.dates[1]
          ).toLocaleDateString()}`,
          email: user.username,
          status: newStatus,
          reason: activeRequest.denyReason || "No reason",
        },
        "user_iLZ3jXyTzXi5zQFlgf5DG"
      );
    } else {
      emailjs.send(
        "service_gekurtf",
        "template_idtafe3", //CONTACT TEMPLATE
        {
          user: activeRequest.user,
          dates: `${new Date(
            activeRequest?.dates[0]
          ).toLocaleDateString()} - ${new Date(
            activeRequest?.dates[1]
          ).toLocaleDateString()}`,
          email: user.username,
          status: newStatus,
          reason: activeRequest.denyReason || "No reason",
        },
        "user_iLZ3jXyTzXi5zQFlgf5DG"
      );
    }

    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/update?containerId=timeoffRequests&databaseId=employees&id=${activeRequest?.id}`,
        {
          status: newStatus,
        }
      )
      .then((res) => {
        setOpen(false);
        setFilteredRequests(
          filteredRequests.map((request) => {
            if (request.id === activeRequest?.id) {
              return {
                ...request,
                status: newStatus,
              };
            }
            return request;
          })
        );
        setActiveRequest({});
      });
  };

  const archive = () => {
    selected.forEach((id) => {
      let updates = {
        archived: true,
      };
      axios
        .post(
          `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/update?containerId=timeoffRequests&databaseId=employees&id=${id}`,
          updates
        )
        .then(() =>
          setFilteredRequests(
            filteredRequests.filter((request) => request.id !== id)
          )
        );
    });
  };

  const handleFilter = (e) => {
    let status = e.target.value;
    switch (status) {
      case "all":
        setFilteredRequests(requests);
        break;
      case "new":
        setFilteredRequests(
          requests.filter((request) => request.status === "New")
        );
        break;
      case "approved":
        setFilteredRequests(
          requests.filter(
            (request) =>
              !request.archived &&
              (request.status === "Approved by Manager" ||
                request.status === "Approved by Leadership")
          )
        );
        break;
      case "denied":
        setFilteredRequests(
          requests.filter(
            (request) =>
              !request.archived &&
              (request.status === "Denied by Manager" ||
                request.status === "Denied by Leadership")
          )
        );
        break;
      case "archived":
        setFilteredRequests(requests.filter((request) => request.archived));
      default:
        break;
    }
    setFilter(status);
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <Stack direction="column" spacing={2}>
            <Typography>
              <b style={{ marginRight: "20px" }}>Employee:</b>
              {activeRequest?.user}
            </Typography>
            <Typography>
              <b style={{ marginRight: "20px" }}>Dates:</b>
              {new Date(activeRequest.dates?.[0]).toLocaleDateString()} -{" "}
              {new Date(activeRequest.dates?.[1]).toLocaleDateString()}{" "}
            </Typography>
            <Typography>
              <b style={{ marginRight: "20px" }}>Hours:</b>
              {activeRequest?.hours}
            </Typography>
            <Typography>
              <b style={{ marginRight: "20px" }}>Reason:</b>
              {activeRequest?.reason}
            </Typography>
            {leadership.includes(user.username.toLowerCase()) && (
              <>
                <TextField
                  value={activeRequest?.denyReason}
                  onChange={(e) =>
                    setActiveRequest({
                      ...activeRequest,
                      denyReason: e.target.value,
                    })
                  }
                  label="Please give a reason if this request is denied"
                  multiline
                  minRows={3}
                />

                <Stack direction="row" spacing={3}>
                  <Button
                    onClick={() => updateStatus("deny")}
                    sx={{ fontSize: "12px" }}
                    variant="outlined"
                    fullWidth
                  >
                    deny
                  </Button>
                  <Button
                    onClick={() => updateStatus("approve")}
                    sx={{ fontSize: "12px" }}
                    variant="contained"
                    fullWidth
                  >
                    approve
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </Box>
      </Modal>
      <Stack direction="row" spacing={5} alignItems="center">
        <Typography variant="h6">Time Off Requests</Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Select
            size="small"
            onChange={handleFilter}
            value={filter}
            sx={{ bgcolor: "white" }}
          >
            <MenuItem value="all">All Requests</MenuItem>
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="denied">Denied</MenuItem>
            <MenuItem value="archived">Archived</MenuItem>
          </Select>
        </Box>
        <Button
          color="error"
          sx={{ fontSize: "12px" }}
          variant="outlined"
          onClick={archive}
        >
          archive selected
        </Button>
      </Stack>
      <Paper sx={{ mt: 2 }}>
        <DataGridPro
          columns={columns}
          rows={filteredRequests}
          density="compact"
          onRowClick={handleOpen}
          autoPageSize
          autoHeight
          checkboxSelection
          onRowSelectionModelChange={(e) => setSelected(e)}
        />
      </Paper>
    </>
  );
}

export default Requests;
