import React, { useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { DeleteOutline, Send } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Fab from "@mui/material/Fab";
import DepartmentSelect from "./components/departmentSelect";
import axios from "axios";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Clear from "@mui/icons-material/Clear";
import {
  generateEmailHtml,
  isValid,
  itemTemplate,
  initialValues,
} from "./functions/functions";
import { DirectoryContext } from "../../App";
import { Autocomplete } from "@mui/material";

const { BlobServiceClient } = require("@azure/storage-blob");
const blobSasUrl =
  "https://tbconnectstorage.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-01-30T08:03:13Z&st=2024-01-30T00:03:13Z&spr=https&sig=kTFgjI0cbH%2BcDGLdPTGMljV8hlvklkttnlps5wyHxNw%3D";
const blobServiceClient = new BlobServiceClient(blobSasUrl);
const containerName = "siteimages";
const containerClient = blobServiceClient.getContainerClient(containerName);

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const MobileExpenseReport = () => {
  const [items, setItems] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [report, setReport] = useState(initialValues);
  const [errors, setErrors] = useState({
    employee: false,
    department: false,
    position: false,
    manager: false,
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel.id : false);
  };

  const saveForLater = () => {
    console.log(items);
    localStorage.setItem(
      "saved report",
      JSON.stringify({ ...report, items: items })
    );
    alert("Report saved!");
  };

  const addItem = () => {
    const id = uuidv4();
    let newItem = {
      ...itemTemplate,
      id: id,
    };
    setItems([...items, newItem]);
    setExpanded(id);
  };

  const removeItem = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const removeFile = (item) => {
    setItems((items) =>
      items.map((i) => (i.id === item.id ? { ...i, attachments: [] } : i))
    );
  };

  const updateItemWithDebounce = (id, field, value) => {
    const updatedItems = items.map((item) => {
      if (item.id === id) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setItems(updatedItems);
  };

  const submit = async () => {
    const valid = isValid(report, setErrors);

    try {
      // Save expense report to database
      let obj = {
        rows: items,
        manager: report.manager,
        user: report.employee,
        date: report.date,
        ccMyself: report.ccMyself,
        total: report.total,
        id: uuidv4(),
      };
      await axios.post(
        `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/save?containerId=expenseReports&databaseId=employees`,
        obj
      );
    } catch (err) {
      alert("error saving expense report to database", err);
    }

    if (valid) {
      try {
        let email = generateEmailHtml(items, report, null, null);

        await axios
          .get(
            "https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/getAuth"
          )
          .then((res) => {
            console.log(res);
            // send email to estimator to notify them of the new request, with link to request
            axios
              .post(
                `https://my-tb-cors.herokuapp.com/https://graph.microsoft.com/v1.0/users/no-reply@evergreenbrands.com/sendMail`,
                email,
                {
                  headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${res.data.access_token}`,
                  },
                }
              )
              .then((res) => {
                console.log(res);
                localStorage.setItem("name", report.employee);
                localStorage.setItem("department", report.department);
                localStorage.setItem("position", report.position);
                localStorage.setItem("manager", report.manager);
                alert("Expense report submitted successfully!");
              });
          });
        // reset report to initial values
        setReport(initialValues);

        // reset items to empty array
        setItems([]);
      } catch (error) {
        console.log(error);
        alert("Error submitting expense report", error);
      }
      // send report via email
    } else {
      // show error
    }
  };

  const handleFileUpload = (e, id) => {
    const file = e.target.files[0];

    setItems((items) =>
      items.map((item) =>
        item.id === id ? { ...item, attachments: [file] } : item
      )
    );
  };

  useEffect(() => {
    // update report total when items change
    let total = items.reduce((acc, item) => acc + Number(item.total), 0);
    setReport({ ...report, total: total });
  }, [items]);

  const handleTextChange = (e) => {
    setReport({ ...report, [e.target.name]: e.target.value });
    setErrors({
      ...errors,
      [e.target.name]: false,
    });
  };

  return (
    <>
      <Typography sx={{ mb: 2, color: "#707070", fontWeight: 700 }}>
        Employee Info:
      </Typography>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          bgcolor: "white",
          px: 2,
          py: 4,
          mb: 4,
          border: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <TextField
          fullWidth
          label="Employee"
          value={report.employee}
          name="employee"
          onChange={handleTextChange}
          size="small"
          required
          error={errors.employee}
          helperText={errors.employee && "This field is required"}
        />
        <DepartmentSelect
          variant="outlined"
          handleChange={(e) =>
            setReport({ ...report, department: e.target.value })
          }
          value={report.department}
        />
        <TextField
          fullWidth
          label="Position"
          value={report.position}
          onChange={handleTextChange}
          name="position"
          size="small"
          required
          error={errors.position}
          helperText={errors.position && "This field is required"}
        />
        <TextField
          fullWidth
          label="Manager"
          name="manager"
          size="small"
          variant="standard"
          onChange={(e) => setReport({ ...report, manager: e.target.value })}
          value={report.manager}
          required
        />
      </Stack>

      <Typography sx={{ my: 2, color: "#707070", fontWeight: 700 }}>
        Expenses:
      </Typography>
      {items.length === 0 && (
        <Typography variant="body2" sx={{ textAlign: "center", mb: 2 }}>
          No items to show
        </Typography>
      )}
      {items.map((item) => {
        return (
          <Accordion
            key={item.id}
            expanded={expanded === item.id}
            onChange={handleChange(item)}
          >
            <AccordionSummary>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography sx={{ flexGrow: 1 }}>{item.description}</Typography>
                <IconButton color="error" onClick={() => removeItem(item.id)}>
                  <DeleteOutline fontSize="small" color="error" />
                </IconButton>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="column" spacing={2}>
                <TextField
                  fullWidth
                  label="Date"
                  type="date"
                  value={item.date}
                  onChange={(e) =>
                    updateItemWithDebounce(item.id, "date", e.target.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  required
                />
                <TextField
                  fullWidth
                  label="Description"
                  value={item.description}
                  onChange={(e) =>
                    updateItemWithDebounce(
                      item.id,
                      "description",
                      e.target.value
                    )
                  }
                  size="small"
                  required
                />
                <TextField
                  fullWidth
                  label="Project"
                  value={item.project}
                  onChange={(e) =>
                    updateItemWithDebounce(item.id, "project", e.target.value)
                  }
                  size="small"
                />
                <TextField
                  fullWidth
                  label="Total"
                  value={item.total}
                  onChange={(e) =>
                    updateItemWithDebounce(item.id, "total", e.target.value)
                  }
                  size="small"
                  type="number"
                  min="0"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />

                {item.attachments.length > 0 ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2" sx={{ flexGrow: 1 }}>
                      {item.attachments[0].name}
                    </Typography>
                    <IconButton onClick={() => removeFile(item)} size="small">
                      <Clear color="error" fontSize="small" />
                    </IconButton>
                  </Box>
                ) : (
                  <Button
                    component="label"
                    variant="outlined"
                    size="small"
                    startIcon={<CloudUploadIcon />}
                  >
                    Attach file
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(e) => handleFileUpload(e, item.id)}
                    />
                  </Button>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}

      {items.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Typography sx={{ color: "#707070", fontWeight: 700, mr: 2 }}>
            Total: $
            {parseInt(report.total) ? Number(report.total).toFixed(2) : 0}
          </Typography>
        </Box>
      )}
      <Button
        onClick={addItem}
        size="small"
        fullWidth
        variant="contained"
        sx={{ mt: items.length === 0 ? 0 : 2, bgcolor: "#009dff", mb: "20vh" }}
      >
        Add Item
      </Button>
      <Fab
        color="primary"
        sx={{ position: "fixed", bottom: "25px", right: "25px", px: 4 }}
        variant="extended"
        size="small"
        onClick={submit}
      >
        Submit
        <Send sx={{ fontSize: "0.9rem", ml: 1 }} />
      </Fab>
    </>
  );
};

export default MobileExpenseReport;
