import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import * as emailjs from "emailjs-com";

// local imports
import WfhRequest from "../calendar/wfhRequest";

// mui imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const statusColors = {
  "Under Review": "warning",
  Approved: "success",
  Denied: "error",
};

const updateRequest = async (request, updates) => {
  try {
    const response = await axios.post(
      `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/update?containerId=calendar&databaseId=employees&id=${request.id}`,
      updates
    );
    console.log("update response", response);
  } catch (err) {
    console.error(err);
  }
};

function WfhRequests() {
  const [requests, setRequests] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeRequest, setActiveRequest] = useState(null);

  const { accounts } = useMsal();
  const account = accounts?.[0];
  const user = account?.name;

  // send email notification to notify HR that a request has been submitted
  const sendNotification = useCallback(
    async (date, status, user, user_email) => {
      try {
        emailjs.send(
          "service_gekurtf",
          "template_4me0u4d",
          {
            user: user,
            date: date,
            user_email: user_email,
            status: status,
          },
          "user_iLZ3jXyTzXi5zQFlgf5DG"
        );
      } catch (err) {
        console.error("Error sending notification", err);
      }
    },
    []
  );

  const fetchRequests = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/getAll?containerId=calendar&databaseId=employees`
      );

      const userRequests =
        user === "Ethan Hoshino" || user === "Sarah Carter"
          ? response.data.filter((req) => req.wfh?.request)
          : response.data.filter(
              (request) => request.employee === user && request.wfh?.request
            );
      setLoading(false);
      setRequests(userRequests.sort((a, b) => b._ts - a._ts));
    } catch (err) {
      console.error("Error fetching requests", err);
    }
  }, []);

  useEffect(() => {
    fetchRequests();
  }, [user]);

  const updateStatus = async (status) => {
    const updates = {
      wfh: {
        ...activeRequest.wfh,
        status,
      },
    };

    try {
      // update status in database
      await updateRequest(activeRequest, updates);

      // notify user
      await sendNotification(
        new Date(activeRequest.wfh?.date)?.toLocaleDateString(),
        status,
        activeRequest.employee,
        activeRequest.employeeEmail
      );
    } catch (err) {
      console.error("Error sending notification", err);
    }

    // update status locally
    setRequests((prev) =>
      prev.map((req) =>
        req.id === activeRequest.id ? { ...req, ...updates } : req
      )
    );

    // close menu
    setAnchorEl(null);
    setActiveRequest(null);
  };

  const columns = [
    {
      field: "_ts",
      headerName: "Submitted On",
      width: 150,
      renderCell: (params) =>
        new Date(params.row._ts * 1000).toLocaleDateString(),
    },
    {
      field: "employee",
      headerName: "Employee",
      width: 180,
    },
    {
      field: "date",
      headerName: "WFH Date",
      renderCell: (params) =>
        params.row.wfh?.date
          ? new Date(params.row.wfh?.date).toLocaleDateString()
          : "",
      width: 200,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 300,
      renderCell: (params) => params.row.wfh?.reason,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const status = params.row.wfh?.status;
        return user === "Sarah Carter" || user === "Ethan Hoshino" ? (
          <Chip
            label={status}
            color={statusColors[status]}
            size="small"
            variant="outlined"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setActiveRequest(params.row);
            }}
          />
        ) : (
          <Chip
            label={status}
            color={statusColors[status]}
            size="small"
            variant="outlined"
          />
        );
      },
      width: 200,
    },
  ];

  const updateArray = (newRequests) => {
    console.log("newRequests", newRequests);
    setRequests((prev) => [...newRequests, ...prev]);
  };

  return (
    <>
      <Menu
        onClose={() => {
          setActiveRequest(null);
          setAnchorEl(null);
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
      >
        <MenuItem onClick={() => updateStatus("Under Review")}>
          Under Review
        </MenuItem>
        <MenuItem onClick={() => updateStatus("Approved")}>Approved</MenuItem>
        <MenuItem onClick={() => updateStatus("Denied")}>Denied</MenuItem>
      </Menu>

      {/* <Chip label="jim@condoshield is an executive" color="success" /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">Work from Home Requests</Typography>

        <WfhRequest updateArray={updateArray} />
      </Box>

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {requests.length > 0 && (
        <Box
          sx={{
            height: "calc(100vh - 150px)",
            width: "100%",
            bgcolor: "white",
          }}
        >
          <DataGrid rows={requests} columns={columns} pageSize={10} />
        </Box>
      )}
      {requests.length === 0 && !loading && (
        <Typography sx={{ textAlign: "center" }} variant="body1">
          No requests found
        </Typography>
      )}
    </>
  );
}

export default WfhRequests;
