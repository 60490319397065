import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// other imports
import { useMsal } from "@azure/msal-react";
import dayjs from "dayjs";
import axios from "axios";

// local imports
import { loginRequest } from "../../config/auth";

// mui imports
import Typography from "@mui/material/Typography";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { set } from "date-fns";

function Employee() {
  const { accounts, instance } = useMsal();
  const user = accounts[0];
  const params = useParams();
  const { id } = params;
  const [employee, setEmployee] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [originalBirthday, setOriginalBirthday] = useState("");
  const [originalHireDate, setOriginalHireDate] = useState("");

  useEffect(() => {
    console.log(user);
    if (
      user.username.toLowerCase() === "carters@transblue.org" ||
      user.username.toLowerCase() === "jim.wescott@transblue.org" ||
      user.username.toLowerCase() === "jim.wescott@evergreenbrands.com"
    ) {
      setAdmin(true);
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/getById?id=${id}&containerId=employees&databaseId=employees`
      )
      .then((res) => {
        console.log(res);
        setEmployee(res.data[0]);
        setOriginalBirthday(res.data[0].birthday);
        setOriginalHireDate(res.data[0].hireDate);
      });
  }, []);

  const handleChange = (e) => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };
  const textFieldProps = {
    fullWidth: true,
    size: "small",
    onChange: handleChange,
  };

  const handleBusinessPhoneChange = (e) => {
    setEmployee({ ...employee, businessPhones: [e.target.value] });
  };

  const updateBirthday = (e) => {
    // we want to update the birthday in the state

    //
    setEmployee({ ...employee, birthday: new Date(e.target.value) });
  };

  const saveUpdates = async () => {
    // get microsoft auth
    const activeAccount = await instance.getActiveAccount();
    console.log(activeAccount);
    let graphAPIToken;
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: activeAccount,
      });
      graphAPIToken = response.accessToken;
      console.log(graphAPIToken);
    } catch (err) {
      alert("Error getting access token from Microsoft");
      return;
    }

    // Save updates to employee
    let updates = {
      department: employee.department,
      displayName: employee.displayName,
      faxNumber: employee.faxNumber,
      mobilePhone: employee.mobilePhone,
      jobTitle: employee.jobTitle,
    };

    if (employee.businessPhones) {
      updates.businessPhones = employee.businessPhones;
    }

    try {
      // update employee in microsoft
      await axios
        .patch(
          `https://my-tb-cors.herokuapp.com/https://graph.microsoft.com/v1.0/users/${id}`,
          updates,
          {
            headers: {
              Authorization: `Bearer ${graphAPIToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          setSnackbarOpen(true);
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }

    try {
      await axios
        .post(
          `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/update?databaseId=employees&containerId=employees&id=${id}`,
          {
            ...updates,
            birthday: new Date(dayjs(employee?.birthday)),
            hireDate: new Date(employee?.hireDate),
          }
        )
        .then((res) => console.log(res));
    } catch (err) {
      console.log(err);
      alert(err);
    }

    if (employee.birthday !== originalBirthday) {
      console.log(employee.birthday);
      try {
        // update employee in microsoft
        await axios
          .patch(
            `https://my-tb-cors.herokuapp.com/https://graph.microsoft.com/v1.0/users/${id}`,
            { birthday: new Date(employee.birthday) },
            {
              headers: {
                Authorization: `Bearer ${graphAPIToken}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            setSnackbarOpen(true);
          });
      } catch (err) {
        console.log(err);
        alert(err);
      }
    }

    if (employee.hireDate !== originalHireDate) {
      console.log(employee.hireDate);
      try {
        // update employee in microsoft
        await axios
          .patch(
            `https://my-tb-cors.herokuapp.com/https://graph.microsoft.com/v1.0/users/${id}`,
            { hireDate: new Date(employee.hireDate) },
            {
              headers: {
                Authorization: `Bearer ${graphAPIToken}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            setSnackbarOpen(true);
          });
      } catch (err) {
        console.log(err);
        alert(err);
      }
    }
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
      >
        <Alert severity="success" variant="filled">
          Employee updated successfully
        </Alert>
      </Snackbar>
      <Button
        onClick={() => window.close()}
        sx={{ textTransform: "capitalize", mb: 2 }}
      >
        <ArrowBackIos fontSize="small" sx={{ mr: 1 }} />
        Back to org chart
      </Button>
      {employee && (
        <Box
          sx={{
            maxWidth: "600px",
            bgcolor: "white",
            border: "1px solid rgba(0,0,0,.2)",
            p: 3,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 1,
            }}
          >
            <Avatar sx={{ width: 56, height: 56 }} src={employee?.image}>
              {employee?.displayName?.[0]}
            </Avatar>
          </Box>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            {employee?.displayName}
          </Typography>
          <Typography variant="subtitle2" sx={{ textAlign: "center", my: 1 }}>
            {employee?.mail}
          </Typography>
          <Stack direction="column" spacing={3}>
            {employee?.directReports?.length > 0 && (
              <AvatarGroup
                max={4}
                sx={{
                  mb: 2,
                  justifyContent: "center",
                  "& .MuiAvatar-root": {
                    width: 30, // Adjust the width of the avatars
                    height: 30, // Adjust the height of the avatars
                  },
                }}
              >
                {employee?.directReports?.map((e) => (
                  <Avatar alt={e.displayName} src={e.image} />
                ))}
              </AvatarGroup>
            )}
            <TextField
              label="Employee Name"
              {...textFieldProps}
              value={employee?.displayName}
              name="displayName"
              InputProps={{
                readOnly: !admin,
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Birthday"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                value={dayjs(employee?.birthday)}
                onChange={(newValue) =>
                  setEmployee({ ...employee, birthday: newValue })
                }
              />
              <DatePicker
                label="Hire Date"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                value={dayjs(employee?.hireDate)}
                onChange={(newValue) =>
                  setEmployee({ ...employee, hireDate: newValue })
                }
              />
            </LocalizationProvider>
            {/* <TextField
              label="Birthday"
              {...textFieldProps}
              value={dayjs(employee?.birthday).format("MM/DD/YYYY")}
              name="birthday"
              InputProps={{
                readOnly: !admin,
              }}
            /> */}
            <TextField
              label="Department"
              {...textFieldProps}
              value={employee?.department}
              name="department"
              InputProps={{
                readOnly: !admin,
              }}
            />
            <TextField
              label="Job Title"
              {...textFieldProps}
              value={employee?.jobTitle}
              name="jobTitle"
              InputProps={{
                readOnly: !admin,
              }}
            />
            <TextField
              label="Business Phone"
              value={employee?.businessPhones?.[0]}
              name="businessPhone"
              InputProps={{
                readOnly: !admin,
              }}
              fullWidth
              size="small"
              onChange={handleBusinessPhoneChange}
            />
            <TextField
              label="Mobile Phone"
              {...textFieldProps}
              value={employee?.mobilePhone}
              name="mobilePhone"
              InputProps={{
                readOnly: !admin,
              }}
            />
            <TextField
              label="Extension"
              {...textFieldProps}
              value={employee?.faxNumber}
              name="faxNumber"
              InputProps={{
                readOnly: !admin,
              }}
            />
            {admin && (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  sx={{ textTransform: "capitalize", mr: 2 }}
                  size="small"
                  variant="outlined"
                  disableElevation
                  //   onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={saveUpdates}
                >
                  Save Updates
                </Button>
              </Box>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
}

export default Employee;
