import * as msal from "@azure/msal-browser";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import login from "../../images/login.jpeg";
import logo from "../../images/logo.png";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/auth";

function Login() {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  const allowedTenants = [
    "transblue",
    "evergreenbrands",
    "condoshield",
    "honey4hope",
    "mbridge",
  ];

  async function msalLogin() {
    await instance
      .loginRedirect(loginRequest)
      .then((res) => {
        if (
          allowedTenants.some((tenant) =>
            res.account.username.toLowerCase().includes(tenant)
          )
        ) {
          // navigate("/dashboard");
        } else {
          alert(
            "Oops! You do not have access to this application. Please contact your administrator."
          );
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Box sx={{ width: "100%", height: "100vh" }}>
        <img
          src={login}
          alt=""
          style={{
            width: "100%",
            height: "100vh",
            position: "relative",
            filter: "blur(2px)",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: 1,
            bgcolor: "rgba(0,0,0,.9)",
            borderRadius: "5px",
            width: "300px",
            textAlign: "center",
            p: 4,
          }}
        >
          <img src={logo} alt="" style={{ width: "180px" }} />

          <Typography variant="h5" sx={{ color: "white", mt: 2, mb: 4 }}>
            EMPLOYEE MANAGEMENT
          </Typography>
          <Button
            onClick={msalLogin}
            variant="contained"
            fullWidth
            sx={{ bgcolor: "#0b56a4" }}
          >
            login
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            bgcolor: "rgba(0,0,0,.4)",
          }}
        ></Box>
      </Box>
    </>
  );
}

export default Login;
