import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import * as emailjs from "emailjs-com";

// mui imports
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

// constants
const initialValues = {
  startDate: dayjs(),
  endDate: dayjs(),
  reason: "",
  user: "",
  days: 1,
};

const modalProps = {
  sx: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    px: 3,
    py: 2,
    borderRadius: 1,
  },
  display: "flex",
  flexDirection: "column",
  gap: 2,
};

const modalButtonProps = {
  size: "small",
  sx: {
    textTransform: "capitalize",
  },
};

const datePickerSlotProps = {
  slotProps: {
    textField: {
      size: "small",
      fullWidth: true,
      sx: {
        bgcolor: "white",
      },
    },
  },
};

function WfhRequest({ updateArray }) {
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [alertOpen, setAlertOpen] = useState(false);

  const { accounts } = useMsal();
  const account = accounts[0];

  console.log(account);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setFormValues(initialValues);
    setOpen(false);
  };

  // send email notification to notify HR that a request has been submitted
  const sendNotification = useCallback(async () => {
    try {
      emailjs.send(
        "service_gekurtf",
        "template_euw2x9o", //CONTACT TEMPLATE
        {
          user: account?.name,
          dates: `${formValues.startDate.format(
            "MM/DD/YYYY"
          )} - ${formValues.endDate.format("MM/DD/YYYY")}`,
          reason: formValues.reason,
        },
        "user_iLZ3jXyTzXi5zQFlgf5DG"
      );
    } catch (err) {
      console.error("Error sending notification", err);
    }
  }, [formValues]);

  const getArrayOfDates = () => {
    const dates = [formValues.startDate?.$d];

    for (let i = 1; i < formValues.days; i++) {
      dates.push(formValues.startDate.add(i, "day").$d);
    }
    return dates;
  };

  const getDbObjects = (dates) => {
    return dates.map((date) => {
      return {
        type: "WFH",
        employee: account?.name,
        employeeEmail: account?.username,
        created: new Date().getTime(),
        wfh: {
          recurring: false,
          date: date,
          daysOfWeek: [],
          request: true,
          status: "Under Review",
          reason: formValues.reason,
        },
        id: uuidv4(),
      };
    });
  };

  const saveToDb = useCallback(async () => {
    const dates = getArrayOfDates();
    const dbObjects = getDbObjects(dates);

    try {
      dbObjects.forEach(async (obj) => {
        await axios.post(
          `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/save?containerId=calendar&databaseId=employees`,
          obj
        );
      });

      return dbObjects.map((obj) => ({
        ...obj,
        _ts: new Date().getTime() / 1000,
      }));
    } catch (err) {
      console.error("Error saving to db", err);
    }
  }, [formValues]);

  const submit = async () => {
    try {
      const newRequests = await saveToDb();
      console.log("requests", newRequests);
      await sendNotification();
      handleClose();
      setAlertOpen(true);

      if (updateArray) {
        updateArray(newRequests);
      }
    } catch (err) {
      console.error("Error submitting request", err);
    }
  };

  useEffect(() => {
    console.log(formValues.startDate);
    if (formValues.startDate && formValues.endDate) {
      const days = formValues.endDate.diff(formValues.startDate, "day");
      setFormValues({
        ...formValues,
        days: days + 1,
      });
    }
  }, [formValues.startDate, formValues.endDate]);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="success"
          variant="filled"
        >
          Request submitted successfully
        </Alert>
      </Snackbar>
      <Modal open={open} onClose={handleClose}>
        <Box {...modalProps}>
          <Typography variant="h6">Request to Work from Home</Typography>
          <Box
            sx={{
              bgcolor: "rgba(0,0,0,.02)",
              borderRadius: 1,
              p: 2,
              pb: 3,
              border: "1px solid rgba(0,0,0,.12)",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box>
                <Typography variant="caption">From</Typography>
                <DatePicker
                  value={formValues.startDate}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      startDate: e,
                    })
                  }
                  {...datePickerSlotProps}
                />
              </Box>
              <Box>
                <Typography variant="caption">To</Typography>
                <DatePicker
                  value={formValues.endDate}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      endDate: e,
                    })
                  }
                  slotProps={{
                    textField: {
                      sx: {
                        bgcolor: "white",
                      },
                      size: "small",
                      fullWidth: true,
                      error: formValues.endDate.isBefore(formValues.startDate),
                      helperText: formValues.endDate.isBefore(
                        formValues.startDate
                      )
                        ? "End date must be after start date"
                        : null,
                    },
                  }}
                  error
                />
              </Box>
            </LocalizationProvider>

            {formValues.days > 0 && (
              <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
                {formValues.days} {formValues.days > 1 ? "Days" : "Day"}
              </Typography>
            )}

            <TextField
              label="Reason"
              fullWidth
              multiline
              value={formValues.reason}
              onChange={(e) =>
                setFormValues({ ...formValues, reason: e.target.value })
              }
              minRows={3}
              sx={{ bgcolor: "white" }}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button {...modalButtonProps} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={formValues.endDate.isBefore(formValues.startDate)}
              {...modalButtonProps}
              variant="contained"
              onClick={submit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <Button
        startIcon={<CalendarMonth />}
        sx={{ textTransform: "capitalize", mr: 2 }}
        onClick={handleOpen}
      >
        Request WFH
      </Button>
    </>
  );
}

export default WfhRequest;
