import React, { useState, useEffect, createContext } from "react";
import MSGraphService from "./api/MSGraphService";
import Calendar from "./pages/calendar/calendar";
import { Route, Routes } from "react-router-dom";
import Vacation from "./pages/vacation/vacation";
import Requests from "./pages/newRequests/requests";
import Clubhouse from "./pages/clubhouse";
import PageLayout from "./components/pageLayout/pageLayout";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import Admin from "./pages/admin";
import AdminProtectedRoute from "./auth/AdminProtectedRoute";
import { loginRequest } from "./config/auth";
import ManagerAdmin from "./pages/manager-admin";
import ManagerAdminProtectedRoute from "./auth/ManagerAdminProtectedRoute";
import axios from "axios";

import DirectoryHome from "./pages/directory/components/directory-home";
import EmployeeInfo from "./pages/directory/components/employee-info";
import DepartmentInfo from "./pages/directory/components/department-info";
import NewCalendar from "./pages/calendar/newCalendar";
import Dashboard from "./pages/dashboard/dashboard";
// import Dashboard from "./pages/xlsx";

import { LicenseInfo } from "@mui/x-license-pro";
import IntakeRequests from "./pages/intake/intake";
import OpenIntake from "./pages/openIntake/openIntake";
import ExpenseReportWrapper from "./pages/expenseReport/expenseReportWrapper";
import Login from "./pages/login/login";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import logo from "./images/logo.png";
import login from "./images/login.jpeg";
import Typography from "@mui/material/Typography";
import NewOrgChart from "./pages/orgChart/orgChart";
import Employee from "./pages/orgChart/employee";
import WfhRequests from "./pages/wfhRequests/wfhRequests";

LicenseInfo.setLicenseKey(
  "f94999c7685f2608811fb8b48423f2baTz00ODIyNyxFPTE2OTA3Mzk3NTYzNjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export const DirectoryContext = createContext({
  employees: [],
  departments: [],
  executive: false,
});

export const GroupContext = createContext({
  groups: [],
});

function App() {
  const [groups, setGroups] = useState([]);
  const { instance, accounts } = useMsal();

  const account = useAccount(accounts[0] || {});
  const [currentEmployee, setCurrentEmployee] = useState(false);
  const [devManager, setDevManager] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [error, setError] = useState(null);
  const [executive, setExecutive] = useState(false);

  const checkToken = async () => {
    console.log(account);
    const res = await instance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    const token = res.accessToken;
    return token;
  };

  const getGroups = async () => {
    const token = await checkToken();

    if (token) {
      axios
        .get(`https://graph.microsoft.com/v1.0/me/memberOf`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          setGroups(res.data.value.map((group) => group.id));
        });
    }
  };

  /**
   * msal-react is built on the React context API and all parts of your app that require authentication must be
   * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
   * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
   * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
   */

  const allowedTenants = [
    "transblue",
    "evergreenbrands",
    "condoshield",
    "honey4hope",
    "mbridge",
  ];

  const executiveEmails = [
    "vinceo@emeraldcityasphalt.com",
    "toddc@seattlelandscapedesigns.com",
    "jonathan.mcquade@evergreenbrands.com",
    "jim.wescott@evergreenbrands.com",
    "david.vetter@evergreenbrands.com",
    "kealern@transblue.org",
    "nicole.heins@condoshield.org",
    "carters@transblue.org",
    "jim@condoshield.org",
  ];

  useEffect(() => {
    if (account) {
      getGroups();
    }
  }, []);

  async function msalLogin() {
    await instance
      .loginRedirect(loginRequest)
      .then((res) => {
        console.log(res);
        // if (
        //   allowedTenants.some((tenant) =>
        //     res.account.username.toLowerCase().includes(tenant)
        //   )
        // ) {
        //   // navigate("/dashboard");
        // } else {
        //   alert(
        //     "Oops! You do not have access to this application. Please contact your administrator."
        //   );
        // }
      })
      .catch((err) => {
        console.log(err);
        setError(`Login failed: ${err}`);
      });
  }

  const getEmployees = () => {
    axios
      .get(
        `https://my-tb-cors.herokuapp.com/https://nfcaccount-fns.azurewebsites.net/api/getAll?containerId=employees&databaseId=employees`
      )
      .then((res) => {
        console.log(res.data);
        setEmployees(
          res.data
            .filter(
              (u) =>
                u.displayName !== "Aimee Wescott" &&
                u.displayName !== "Condo Shield Team" &&
                u.displayName !== "Glenn Reed"
            )
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
        );
      });
  };

  useEffect(() => {
    if (accounts.length > 0) {
      //navigate("/dashboard");
      getEmployees();
    } else {
      // navigate("/login");
    }
  }, [accounts]);

  useEffect(() => {
    const username = accounts[0]?.username?.toLowerCase();

    if (executiveEmails.includes(username)) {
      setExecutive(true);
    }
  }, [accounts]);

  return (
    <div className="app">
      {accounts.length > 0 ? (
        <AuthenticatedTemplate>
          <GroupContext.Provider value={{ groups }}>
            <DirectoryContext.Provider
              value={{
                employees,
                departments,
                setEmployees,
                setDepartments,
                executive,
              }}
            >
              <PageLayout currentEmployee={currentEmployee}>
                <Routes>
                  <Route exact path="/wfhRequests" element={<WfhRequests />} />
                  <Route exact path="/orgchart" element={<NewOrgChart />} />
                  <Route exact path="/intake" element={<IntakeRequests />} />
                  <Route exact path="/intake/:id" element={<OpenIntake />} />
                  <Route exact path="/dashboard" element={<Dashboard />} />
                  <Route exact path="/calendar" element={<NewCalendar />} />
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/directory" element={<DirectoryHome />} />
                  <Route exact path="/orgchart/:id" element={<Employee />} />
                  <Route
                    exact
                    path="/expenseReport"
                    element={<ExpenseReportWrapper />}
                  />
                  <Route
                    exact
                    path="/directory/employee/:employeeSlug"
                    element={<EmployeeInfo />}
                  />
                  <Route
                    exact
                    path="/directory/departments/:department"
                    element={<DepartmentInfo />}
                  />
                  <Route exact path="/" element={<Dashboard />}></Route>
                  <Route
                    exact
                    path="/clubhouse"
                    element={<Clubhouse />}
                  ></Route>
                  <Route exact path="/requests/*" element={<Requests />} />
                  <Route
                    exact
                    path="/manager-admin/*"
                    element={
                      <>
                        <ManagerAdminProtectedRoute>
                          <ManagerAdmin
                            manager={
                              process.env.NODE_ENV === "production"
                                ? currentEmployee
                                : devManager
                            }
                          />
                        </ManagerAdminProtectedRoute>
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/admin/*"
                    element={
                      <>
                        <AdminProtectedRoute>
                          <Admin />
                        </AdminProtectedRoute>
                      </>
                    }
                  />
                </Routes>
              </PageLayout>
            </DirectoryContext.Provider>
          </GroupContext.Provider>
        </AuthenticatedTemplate>
      ) : (
        <UnauthenticatedTemplate>
          {/* <Box sx={{ width: "100%", height: "100vh" }}>
            <img
              src={login}
              alt=""
              style={{
                width: "100%",
                height: "100vh",
                position: "relative",
                filter: "blur(2px)",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                zIndex: 1,
                bgcolor: "rgba(0,0,0,.9)",
                borderRadius: "5px",
                width: "300px",
                textAlign: "center",
                p: 4,
              }}
            >
              <img src={logo} alt="" style={{ width: "180px" }} />

              <Typography variant="h5" sx={{ color: "white", mt: 2, mb: 4 }}>
                EMPLOYEE MANAGEMENT
              </Typography>
              <Button
                onClick={msalLogin}
                variant="contained"
                fullWidth
                sx={{ bgcolor: "#0b56a4" }}
              >
                login
              </Button>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "100vh",
                position: "absolute",
                top: 0,
                left: 0,
                bgcolor: "rgba(0,0,0,.4)",
              }}
            ></Box>
          </Box> */}
          {!error ? (
            <Box sx={{ width: "100%", height: "100vh" }}>
              <img
                src={login}
                alt=""
                style={{
                  width: "100%",
                  height: "100vh",
                  position: "relative",
                  filter: "blur(2px)",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: 1,
                  bgcolor: "rgba(0,0,0,.9)",
                  borderRadius: "5px",
                  width: "300px",
                  textAlign: "center",
                  p: 4,
                }}
              >
                <img src={logo} alt="" style={{ width: "180px" }} />

                <Typography variant="h5" sx={{ color: "white", mt: 2, mb: 4 }}>
                  EMPLOYEE MANAGEMENT
                </Typography>
                <Button
                  onClick={msalLogin}
                  variant="contained"
                  fullWidth
                  sx={{ bgcolor: "#0b56a4" }}
                >
                  login
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "100vh",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bgcolor: "rgba(0,0,0,.4)",
                }}
              ></Box>
            </Box>
          ) : (
            <Box sx={{ width: "100%", height: "100vh" }}>{error}</Box>
          )}
          {/* <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/" element={<Login />} />
          </Routes> */}
        </UnauthenticatedTemplate>
      )}
    </div>
  );
}

export default App;
