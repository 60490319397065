import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TimeOffForm from "../../forms/timeOff";
import IntakeRequest from "../../forms/intakeRequest";
import it from "../../images/it.jpeg";
import linkedin from "../../images/linkedin.jpeg";
import marketing from "../../images/marketing.jpeg";
import credit from "../../images/credit.jpg";
import expense from "../../images/expense.jpg";
import { GroupContext } from "../../App";
import nationalSales from "../../images/nationalsales.png";
import minute from "../../images/5minute.png";
import weather from "../../images/weather.png";
import report from "../../images/report.png";
import axios from "axios";
import software from "../../images/software.jpg";

const personalLinks = [
  {
    title: "Credit Card Requests",
    href: "https://tinyurl.com/CC-Request",
    id: "ae1b733c-7a46-4e11-9a98-1d420d7bd3ee",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "90%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "8px",
  maxWidth: "500px",
  p: 3,
};

const AppComponent = ({ link, avatar, title, img }) => {
  return (
    <Grid item xs={6} md={4} lg={3}>
      <Card
        sx={{
          width: "100%",
          textAlign: "center",
          borderRadius: 3,
          bgcolor: "transparent",
        }}
        elevation={0}
      >
        <Link href={link} underline="none" target="_blank" rel="noreferrer">
          <CardActionArea>
            <CardContent sx={{ p: 2 }}>
              {/* <Avatar
                sx={{
                  m: "0 auto",
                  width: 50,
                  height: 50,
                  bgcolor: "#0b56a4",
                }}
              >
                {avatar}
              </Avatar> */}
              <img
                src={img}
                style={{ margin: "0 auto", width: "50px", height: "50px" }}
              />
              <Typography
                sx={{ mt: 1, color: "#333333", fontWeight: "semi-bold" }}
              >
                {title}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Link>
      </Card>
    </Grid>
  );
};

const FormComp = ({ img, title, link }) => {
  const navigate = useNavigate();
  return (
    <Grid item xs={6} md={4} lg={3}>
      <Card
        sx={{
          borderRadius: 3,
          width: "100%",
          height: "120px",
          "&:hover": {
            transform: "scale(1.03)",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          },
        }}
      >
        <CardActionArea
          sx={{
            zIndex: 0,
            width: "100%",
            height: "100%",
            position: "relative",
          }}
          onClick={() =>
            link.includes("https")
              ? window.open(link, "_blank")
              : navigate(link)
          }
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              bgcolor: "rgba(0,0,0,.5)",
              zIndex: 1,
              position: "absolute",
            }}
          />
          <img
            src={img}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 0,
              objectFit: "cover",
            }}
            alt=""
          />
          <Typography
            sx={{
              position: "absolute",
              color: "white",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 3,
              width: "100%",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
function Dashboard() {
  const [timeOffOpen, setTimeOffOpen] = useState(false);
  const [intakeOpen, setIntakeOpen] = useState(false);
  const groupContext = useContext(GroupContext);
  const { groups } = groupContext;
  const myLinks = personalLinks.filter((link) => groups.includes(link.id));

  return (
    <>
      <Modal open={timeOffOpen} onClose={() => setTimeOffOpen(false)}>
        <Box sx={style}>
          <Typography variant="h6">Request time off</Typography>
          <Divider sx={{ mt: 1, mb: 3 }} />
          <TimeOffForm setTimeOffOpen={setTimeOffOpen} />
        </Box>
      </Modal>
      <Modal open={intakeOpen} onClose={() => setIntakeOpen(false)}>
        <Box sx={style}>
          <Typography variant="h6">Intake Request</Typography>
          <Divider sx={{ mt: 1, mb: 3 }} />
          <IntakeRequest setIntakeOpen={setIntakeOpen} />
        </Box>
      </Modal>
      {myLinks.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Personal Links
          </Typography>
          <Stack direction={{ xs: "column", md: "row" }} spacing={3} mb={6}>
            {myLinks.map((link) => (
              <Button
                key={link.id}
                variant="contained"
                sx={{ textTransform: "capitalize", bgcolor: "#009dff" }}
                onClick={() => window.open(link.href, "_blank")}
              >
                {link.title}
              </Button>
            ))}
          </Stack>
        </>
      )}
      <Typography variant="h6">General Information / Updates</Typography>
      <ul>
        <li>
          <Typography variant="body1">
            We have a new and improved Org Chart, check it out{" "}
            <Link href="/orgChart">here</Link>
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            We've added the ability save your expense report and come back to it
            later, just click the "Save For Later" button under the submit
            button
          </Typography>
        </li>
      </ul>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Apps
      </Typography>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        mb={2}
        sx={{ width: "100%", maxWidth: "1000px" }}
        alignItems={{ xs: "center", md: "flex-start" }}
      >
        <AppComponent
          link="https://stream.evergreenbrands.com"
          title="5 Minute Friday"
          img={minute}
        />
      </Grid>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Forms
      </Typography>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        mb={6}
        sx={{ width: "100%", maxWidth: "1000px" }}
        alignItems={{ xs: "center", md: "flex-start" }}
      >
        <FormComp
          link="https://evb.gogenuity.com/help_center/workspaces/7971"
          img={it}
          title="IT Requests"
        />
        <FormComp link="/expenseReport" title="Expense Report" img={expense} />
        <FormComp
          link="https://forms.office.com/r/JdjZPbJSrL"
          img={linkedin}
          title="New Hire Request"
        />

        <FormComp
          link="https://forms.clickup.com/9009098170/f/8cfqvdu-2731/1CPZWDUPT6DW19HHY5"
          title="Sun Holdings Feature Request"
          img={marketing}
        />
        <FormComp
          link="https://forms.clickup.com/9009098170/f/8cfqvdu-2711/LGNE1UZBZI17IJ1GPR"
          title="Sun Holdings Bug Report"
          img={it}
        />
      </Grid>
    </>
  );
}

export default Dashboard;
